<template>
  <component
    :is="component"
    :close-button-label="i18n(dsTranslations.close)"
    :name="modalName"
    :title
  >
    <template #trigger="{ open }">
      <CmsButtonBase variant="secondary" width="fixed" @click="open">
        <slot></slot>
      </CmsButtonBase>
    </template>

    <template #body="{ close }">
      <ul class="list-none py-24">
        <li
          v-for="doc in documents"
          :key="doc.id"
          class="flex items-center py-20"
        >
          <IconBill class="mr-8" />
          <RevLink :to="doc.link?.href" @click="close">
            {{ doc.title }}
          </RevLink>
        </li>
      </ul>
    </template>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type LegalDocuments } from '@backmarket/http-api/src/api-specs-content/models/legal-page-content'
import CmsButtonBase from '@backmarket/nuxt-layer-cms/CmsButtonBase.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useDangerouslyComputedBreakpoint } from '@backmarket/utils/composables/useDangerouslyComputedBreakpoint'
import { RevDialog } from '@ds/components/Dialog'
import { RevDrawer } from '@ds/components/Drawer'
import { RevLink } from '@ds/components/Link'
import { IconBill } from '@ds/icons/IconBill'
import { dsTranslations } from '@ds/translations'

withDefaults(
  defineProps<{
    title: string
    documents: LegalDocuments
    modalName?: string
  }>(),
  { modalName: 'legal-documents-modal' },
)

const i18n = useI18n()

const breakpoint = useDangerouslyComputedBreakpoint()
const component = computed(() => (breakpoint.value > 1 ? RevDrawer : RevDialog))
</script>
